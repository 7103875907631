'use client';

import styles from '../../styles.module.scss';
import SkeletonBox from '@/components/skeleton/box';

export default function BookGridSkeleton() {
  // Number of skeleton items to show in the grid
  const BOOKS_COUNT = 12;

  return (
    <div>
      {/* Search box skeleton */}
      <div className={styles['search-input-container']}>
        <SkeletonBox
          height={48}
          width="100%"
          style={{ borderRadius: '0.25rem' }}
        />
      </div>

      {/* Results count skeleton */}
      <div className={styles.resultsCount}>
        <SkeletonBox height={24} width="8rem" />
      </div>

      {/* Book grid skeleton */}
      <div className={styles.grid}>
        {[...Array(BOOKS_COUNT)].map((_, index) => (
          <div key={index} style={{ display: 'flex', flexDirection: 'column' }}>
            {/* Book cover image skeleton */}
            <SkeletonBox
              height={240}
              width="100%"
              style={{ borderRadius: '0.25rem' }}
            />

            {/* Book title skeleton */}
            <div style={{ marginTop: '0.5rem' }}>
              <SkeletonBox height={16} width="95%" />
            </div>

            {/* Book author skeleton */}
            <div style={{ marginTop: '0.25rem' }}>
              <SkeletonBox height={16} width="75%" />
            </div>

            {/* Book price skeleton */}
            <div style={{ marginTop: '0.5rem' }}>
              <SkeletonBox height={20} width="50%" />
            </div>
          </div>
        ))}
      </div>

      {/* Pagination skeleton */}
      <div
        className={styles.pagination}
        style={{ justifyContent: 'center', marginTop: '2rem' }}
      >
        <SkeletonBox
          height={40}
          width="20rem"
          style={{ borderRadius: '0.25rem' }}
        />
      </div>
    </div>
  );
}
