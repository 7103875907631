import { omit } from 'sdk';
import { Box, BoxProps } from '../layout/box';
import styles from './styles.module.scss';
import classNames from 'classnames';

interface SkeletonProps extends BoxProps {
  height: 'sm' | 'md' | 'lg' | number;
  width?: string | number;
}
export default function SkeletonBox(props: SkeletonProps) {
  return (
    <Box
      {...omit(props, ['height', 'width'])}
      className={classNames(styles.container, {
        [styles[props.height]]: typeof props.height === 'string',
      })}
      style={{
        ...(typeof props.height === 'number' ? { height: props.height } : {}),
        ...(props.width ? { width: props.width } : {}),
      }}
    />
  );
}