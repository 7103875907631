'use client';

import styles from '../../styles.module.scss';
import SkeletonBox from '@/components/skeleton/box';

export default function HeaderSkeleton() {
  return (
    <div className={styles.header}>
      <div className={styles['header-container']}>
        <div className={styles['header-seller-info']}>
          <div className={styles['header-seller-info-container']}>
            {/* Seller avatar skeleton */}
            <div className={styles['header-seller-info-image']}>
              <SkeletonBox
                height={160}
                width={160}
                style={{ borderRadius: '50%' }}
              />
            </div>

            <div style={{ marginLeft: '2rem', width: '100%' }}>
              {/* Seller name skeleton */}
              <SkeletonBox height={32} width="60%" />

              {/* Seller username skeleton */}
              <div style={{ marginTop: '0.5rem' }}>
                <SkeletonBox height={24} width="40%" />
              </div>

              {/* Seller review stars skeleton */}
              <div style={{ marginTop: '0.5rem' }}>
                <SkeletonBox height={20} width="8rem" />
              </div>
            </div>
          </div>

          {/* Seller bio skeleton */}
          <div style={{ marginTop: '1rem' }}>
            <div style={{ marginBottom: '8px' }}>
              <SkeletonBox height={16} width="100%" />
            </div>
            <div style={{ marginBottom: '8px' }}>
              <SkeletonBox height={16} width="95%" />
            </div>
            <SkeletonBox height={16} width="90%" />
          </div>
        </div>

        <div className={styles['header-seller-actions']}>
          {/* Seller stats skeleton */}
          <div className={styles['header-seller-stats']}>
            {[...Array(3)].map((_, i) => (
              <div key={i} className={styles['header-seller-stats-stat']}>
                <SkeletonBox height={16} width="70%" />
                <div style={{ marginTop: '0.5rem' }}>
                  <SkeletonBox height={24} width="40%" />
                </div>
              </div>
            ))}
          </div>

          {/* Action buttons skeleton */}
          <div className={styles['header-seller-buttons']}>
            <div className={styles['header-seller-buttons--follow']}>
              <SkeletonBox
                height={48}
                width="100%"
                style={{ borderRadius: '0.5rem' }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
